.container {
    &.container--justify-left {
        .cmp-container {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            text-align: left;
        }
    }
}
