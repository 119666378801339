
//== Font

$primary-font: 'Area Expanded', sans-serif;
$secondary-font: 'Burford Base', sans-serif;
$lunchbox-font: 'LunchBox Slab', sans-serif;
$font-size: 1.6rem;
$font-height: 1.25;

//== Color

$color-background: #1f2124;
$color-foreground: #fff;
$color-link: #fe671d;
$color-accent: #fce22b;
$color-accent-lighter: $color-accent;
$color-accent-darker: #c18d00;
$color-shade-1: $color-foreground;
$color-shade-2: $color-foreground;
$color-shade-3: #1d2224;
$color-shade-4: $color-link;

$error: #eb000c;
$error-darker: #b20009;
$success: #6ba43a;
$success-darker: #347100;

$color-text: $color-foreground;
$color-text-inverted: #cb4401;
$color-shadow: rgba(0, 0, 0, 0.16);
$color-transparent: rgba(0, 0, 0, 0);

//== media

$breakpoint-mobile: 992px;
$breakpoint-large: 1000px;
$screen-lg: 992px;