body {
	overscroll-behavior: none;
	overflow-x: hidden;
}
.Homepage {
	background-color: #000;
	overflow: hidden;
	.cmp-button, .btn-primary {
		background-color: $color-link;
		border: 0.3rem solid $color-accent;
		color: black;
		font-size: 2.0rem;
		letter-spacing: -0.15rem;
		padding: 0.5rem 2.0rem 0.6rem;
		text-decoration: none;
		transition: all .5s ease-in-out;
		.cmp-button__text {
			color: black;
			font-size: 2.0rem;
			letter-spacing: -0.15rem;
		}
		&:hover {
			background-color: $color-accent;
			border-color: $color-accent;
			.cmp-button__text {
				color: black;
			}
		}
	}
}
.cmp-brandhero {
	align-items: center;
	display: flex;
	flex-direction: column;
	position: relative;

	.brand-hero__bottle-image {
		max-width: 615px;
	}
}

.primary {
	.cmp-button {
		border-radius: 0;
	}
} 
.brand-hero__background-image {
	width: 100%;
}
.recipe-cards {
	margin: 5.0rem 0;
	padding: 3.0rem 0 6.0rem;
	display: flex;
	.aem-Grid {
		&::before, &::after {
			display: none;
		}
	}
	.cmp-title__text {
		background: url('./resources/images/stars.png') transparent bottom no-repeat;
		color: $color-accent;
		font-family: $secondary-font;
		font-size: 4.2rem;
		line-height: 1;
		padding-bottom: 1.0rem;
		text-align: center;

		@media screen and (max-width: 600px) {
			font-size: 3.2rem;
		}	
	}
	.aem-Grid--default--12 {
		display: grid;
		gap: 5.0rem;
		grid-template-columns: repeat(3, 1fr);
		@media screen and (max-width: 1200px) {
			gap: 2.5rem;
		}
		@media (max-width: $breakpoint-large) {
			gap: 10.0rem;
			grid-template-columns: 1fr;
		}
		.cmp-image__image {
			width: auto;
			max-width: 100%;
			@media (max-width: $breakpoint-large) {
				width: 50%;
			}
		}
	}
	.aem-Grid.aem-Grid--default--12 > .aem-GridColumn.aem-GridColumn--default--4 {
		float: none;
		width: auto;
		.cmp-container {
			background-position: bottom left;
			height: 100%;
			padding: 4.0rem 0 0;
			@media (max-width: $breakpoint-large) {
				padding: 2.0rem 0;
			}
		}
	}
	.image{
		&.standard {
			text-align: right;
			@media (max-width: $breakpoint-large) {
				text-align: center;
			}
		}
	}
	.text {
		font-size: 2.4rem;
		padding-top: 3.0rem;
		text-align: center;
	}
}
.non-responsive {
	.cmp-image__image {
		width: auto;
		@media (max-width: $breakpoint-large) {
			width: 100%;
		}
	}
}
.mango-cta, .mango-cta2 {
	@media screen and (max-width: 600px) {
		padding-right: 0;
	}	
	margin: 5.0rem 0;
	padding: 4.0rem;
	text-align: center;
	.aem-Grid.aem-Grid--default--12 > .aem-GridColumn.aem-GridColumn--offset--default--0 {
		@media screen and (max-width: 600px) {
			padding: 0;
		}		
	}
	.cta-text {
		font-size: 1.6rem;
		padding: 0 6.0rem;
		@media screen and (max-width: 600px) {
			padding: 0 3.0rem;
		}
	}
	.cmp-title__text {
		font-family: $lunchbox-font;
		font-size: 6.4rem;
		line-height: .95;

		@media screen and (max-width: 600px) {
			font-size: 3.2rem;
			line-height: 1.15;
		}
	}
	.title {
		@media screen and (max-width: 600px) {
			padding-right: 3rem;
		}
	}
	.text {
		margin-bottom: 3.0rem;

		@media screen and (max-width: 600px) {
			padding-right: 3rem;
		}

		p, .cmp-text {
			font-family: $primary-font;
			font-weight: 600;
			line-height: 3rem;


			@media screen and (max-width: 600px) {
				font-size: 1.4rem;
				line-height: 2.6rem;
			}
		}
	}
}

.mango-cta {
	background: url('./resources/images/text-background1.png') transparent left center no-repeat;
	background-size: 70%;
	@media (max-width: $breakpoint-large) {
		background-size: cover;
	}
}
.mango-cta2 {
	background: url('./resources/images/text-background2.png') transparent right center no-repeat;
	background-size: 100%;
	@media (max-width: $breakpoint-large) {
		background-size: cover;
	}
}
.brand-hero {
	position: relative;
}

.brand-hero__bottle-image {
	width: 100%; 
	max-width: 800px;
	margin-top: -12rem;
	@media (max-width: $breakpoint-mobile) {
		margin-top: 0;
	}
	
}

.cmp-brandhero {
	position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.brand-hero__content {
	//for video variation
	&.title-image-video {
		align-items: center;
		display: flex;
		flex-direction: column;
		inset: 0;
		justify-content: center;
		position: absolute;
		text-align: center;
		img {
			width: 40% !important;
		}
		.brand-hero__description {
			font-size: 5.0rem;

			p {
				line-height: 2;

				@media (max-width: $breakpoint-mobile) {
					font-size: 3rem;
				}
			}
		}
		// This better be temporary
		.brand-hero__title-image-mobile {
			display: none;
		}
		.brand-hero__controls {

			bottom: 20%;
			left: 20%;
			position: absolute;

			@media (max-width: $breakpoint-mobile) {
				display: none;
			}

			.audio-control {
				display: block;
				background-size: contain;
				background-repeat: no-repeat;
				height: 40px;
				margin-bottom: 10px;
				width: 40px;
				&.muted {
					background: url('data:image/svg+xml,<svg id="sounds-btn" xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40"><g id="mute-btn" transform="translate(-690 -483)"><g id="Rectangle" transform="translate(690 483)" fill="rgba(0,0,0,0.2)" stroke="%23f6f4f2" stroke-width="1" opacity="0.999"><rect width="40" height="40" rx="20" stroke="none"/><rect x="0.5" y="0.5" width="39" height="39" rx="19.5" fill="none"/></g><path id="Path_942" data-name="Path 942" d="M7.374.016,2.8,4.683H.017V9.35H2.8l4.571,4.667H8.517v-14Z" transform="translate(701.483 495.984)" fill="none" stroke="%23f6f4f2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1"/><g id="Group_1291" data-name="Group 1291" transform="translate(701.5 496.982)"><path id="Path_940" data-name="Path 940" d="M12.114,5.634l5,5" transform="translate(0.386 -2.115)" fill="none" stroke="%23f6f4f2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1"/><path id="Path_941" data-name="Path 941" d="M17.114,5.634l-5,5" transform="translate(0.386 -2.115)" fill="none" stroke="%23f6f4f2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1"/></g></g></svg>');
		
				}
		
				&.unmuted {
					background: url('data:image/svg+xml,<svg id="sounds-btn" xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40"><g id="Volumn"><g id="Rectangle" fill="rgba(0,0,0,0.2)" stroke="%23f6f4f2" stroke-width="1" opacity="0.999"><rect width="40" height="40" rx="20" stroke="none"/><rect x="0.5" y="0.5" width="39" height="39" rx="19.5" fill="none"/></g><g id="volumn-2" data-name="volumn" transform="translate(11.5 13.482)"><path id="Path_937" data-name="Path 937" d="M7.374.016,2.8,4.683H.017V9.35H2.8l4.571,4.667H8.517v-14Z" transform="translate(-0.017 -0.498)" fill="none" stroke="%23f6f4f2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1"/><path id="Path_938" data-name="Path 938" d="M11.342,13.008a6.594,6.594,0,0,0,3.939-5.986,6.6,6.6,0,0,0-4-6.014" transform="translate(1.719 -0.49)" fill="none" stroke="%23f6f4f2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1"/><path id="Path_939" data-name="Path 939" d="M10.279,9.288a3.259,3.259,0,0,0-.029-6" transform="translate(1.75 0.231)" fill="none" stroke="%23f6f4f2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1"/></g></g></svg>');
				}
		
			}
		}
	}
	//for image variation
	&:not(.title-image-video) {
		 display: flex;
		 flex-direction: column-reverse;
		 align-items: center;
		 justify-content: center;
		 position: absolute;
    	 top: 10rem;

		@media (max-width: $breakpoint-mobile) {
			top: 0;
		}

		.brand-hero__title {
			text-align: center;
			color: $color-accent;
		}
		
		.brand-hero__description {
			p {
				font-family: $lunchbox-font;
				font-size: 10rem;
				line-height: 2;
				margin: 0;
				text-align: center !important;

				@media (max-width: $breakpoint-mobile) {
					font-size: 5rem;
				}

			}
		}
		.brand-hero__item-cta {
			display: none;
		}
	}
}
