.caption {
	.cmp-image {
	}
	.cmp-image__link {
	}
	.cmp-image__image {
	}
	.cmp-image__title {
		margin-top: 1rem;
		color: $color-shade-3;
		font-size: 0.625em;
	}
}
