#main-footer {
  .cmp-htmlblock {

    .stylelink {
      text-align: center;
      padding-bottom: 40px;
      margin-bottom: 40px;
      position: relative;
      z-index: 2;

      .no-border {
        border: none !important;
        cursor: pointer;
      }

      div {
        margin: 10px 0;
      }

      .ot-sdk-show-settings {
        white-space: nowrap;
        border-bottom: 2px solid #fce22b;
        border-style: none none solid none;
        background-color: transparent;
        margin: 0 2rem;
        text-decoration: none;
        transition: all .25s ease-in-out;
        color: #fe671d !important;
        cursor: pointer;
        display: block !important;

        &:hover {
          border-color: #fce22b;
          color: #fce22b !important;
        }
      }

      #ot-sdk-btn {
        font: inherit;
        border-bottom: 2px solid #fce22b;
        border-style: none none solid none;
        padding: 0;
        margin: auto;

        &:hover {
          background-color: transparent;
        }
      }
    }


    .makepointer {
      cursor: pointer;
    }
  }
}

#onetrust-consent-sdk {
  #onetrust-pc-sdk.otRelFont {
    font-size: 2rem;
  }
}

#onetrust-banner-sdk .ot-sdk-row,
#onetrust-pc-sdk .ot-sdk-row,
#ot-sdk-cookie-policy .ot-sdk-row {
  font-size: 1.75rem;
}