
h1.cmp-title__text {
  font-family: $lunchbox-font;
  font-size: 12rem;
  letter-spacing: -.36rem;
  line-height: 10rem;
  text-transform: uppercase;

  @media screen and (max-width: 600px) {
    font-size: 5rem;
    line-height: 5rem;
  }	
}

h2.cmp-title__text {
  font-family: $lunchbox-font;
  font-size: 6.4rem;
  letter-spacing: -.129rem;
  line-height: 6rem;
  text-transform: uppercase;

  @media screen and (max-width: 600px) {
    font-size: 3.2rem;
    line-height: 3rem;
  }
}

h3.cmp-title__text {
  font-family: $secondary-font;
  font-size: 6rem;
  letter-spacing: -.24rem;
  line-height: 7rem;
  text-transform: uppercase;

  @media screen and (max-width: 600px) {
    font-size: 3rem;
    line-height: 3.5rem;
  }
}

h4.cmp-title__text {
  font-family: $primary-font;
  font-size: 1.6rem;
  @media screen and (max-width: 600px) {
    font-size: 1.4rem;
  }
}

h5.cmp-title__text {
  font-family: $secondary-font;
  font-size: 4.2rem;
  letter-spacing: -0.504rem;
  line-height: 6rem;
  text-transform: uppercase;

  @media screen and (max-width: 600px) {
    font-size: 2.6rem;
    line-height: 3rem;
  }
}

h6.cmp-title__text {
  font-family: $secondary-font;
  font-size: 2.4rem;
  letter-spacing: -.288rem;
  line-height: 1.2rem;
  text-transform: uppercase;

  @media screen and (max-width: 600px) {
    font-size: 2.6rem;
    line-height: 1rem;
  }
}
