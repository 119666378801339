// The fonts were generated using fontpie (https://github.com/pixel-point/fontpie),
// a tool that converts font files into web-compatible formats and generates the necessary CSS code.

@import url('https://use.typekit.net/myi0lwo.css');

@font-face {
  font-family: 'Burford Base';
  font-style: normal;
  font-weight: normal;
  src: url('./resources/fonts/BurfordBase/BurfordBase.woff2') format('woff2');
}

@font-face {
	font-family: 'Burford Line';
	font-style: normal;
	font-weight: normal;
	src: url('./resources/fonts/BurfordLine/BurfordLine.woff2') format('woff2');
}

@font-face {
	font-family: 'LunchBox Slab';
	font-style: normal;
	font-weight: normal;
	src: url('./resources/fonts/LunchBoxSlabBold/LunchBoxSlabBold.woff2') format('woff2');
}

@font-face {
    font-family: 'defaultFont';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('./resources/fonts/SourceSansPro/SourceSansPro-Regular.woff2') format('woff2');
  }
  
  @font-face {
    font-family: 'defaultFont Fallback';
    font-style: normal;
    font-weight: 400;
    src: local('Arial');
    ascent-override: 104.47%;
    descent-override: 28.98%;
    line-gap-override: 0.00%;
    size-adjust: 94.19%;
  }

  @font-face {
    font-family: 'defaultFont';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url('./resources/fonts/SourceSansPro/SourceSansPro-Bold.woff2') format('woff2');
 }
  

@font-face {
    font-family: 'titleFont';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('./resources/fonts/SourceSerifPro/SourceSerifPro-Regular.woff2') format('woff2');
  }
  
  @font-face {
    font-family: 'titleFont Fallback';
    font-style: normal;
    font-weight: 400;
    src: local('Arial');
    ascent-override: 90.75%;
    descent-override: 33.12%;
    line-gap-override: 0.00%;
    size-adjust: 101.16%;
  }

  @font-face {
    font-family: 'Lobster';
    src: url('./resources/fonts/Lobster/Lobster.eot');
    src: url('./resources/fonts/Lobster/Lobster.eot?#iefix') format('embedded-opentype'),
      url('./resources/fonts/Lobster/Lobster.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  * {
    font-family: 'Burford Base';
  }

  