.cmp-container {}

.cmp-container--no-gutters {
    width: 100% !important;
    margin: 0 !important;
}

.cmp-container--hide-on-mobile {
    display: block;
    @media (min-width: $breakpoint-large) {
       display: none;
    }
}

.cmp-container--hide-on-desktop {
    display: none;

    @media (min-width: $breakpoint-large) {
        display: block;
     }
}