.cmp-navigation {
	height: 2.5em;
	margin-right: 0.5em;
	margin-left: 2.5em;
}

.cmp-navigation__group {
	display: flex;
	flex-direction: row;
	margin: 0;
	padding: 0;
	list-style: none;
}
.cmp-navigation__item--level-0 {
	& > a {
	}
	&:hover {
		& > .cmp-navigation__item-link {
		}
	}
	&:focus {
		.cmp-navigation__item-link {
		}
	}
	& > .cmp-navigation__group {
	}
}
.cmp-navigation__item--level-0:nth-child(2) {
	&:after {
	}
	&:hover {
		& > .cmp-navigation__group {
		}
		&:after {
		}
	}
	& .cmp-navigation__group .cmp-navigation__item {
		&:hover {
			& a {
			}
		}
	}
}
.cmp-navigation__item-link {
	text-decoration: none;
}
.cmp-navigation__item--active > .cmp-navigation__item-link {
}

@media (max-width: $breakpoint-mobile) {
	.navigation .cmp-navigation > .cmp-navigation__group {

	}
}
