.cmp-teaser {
  position: relative;
}
.cmp-teaser__image {
  & .cmp-image {
    margin: 0;
  }
  & img {
    display: block;
    height: 30em;
    object-fit: cover;
  }
}
.cmp-teaser__content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.cmp-teaser__content:only-child {
  position: relative;
  top: 0;
  left: 0;
  transform: none;
  .cmp-teaser__pretitle, .cmp-teaser__title, .cmp-teaser__title-link, .cmp-teaser__description  {
    color: $color-text;
  }
}
.cmp-teaser__pretitle {
  color: #ffffff;
  font-weight: 300;
  font-size: 0.625em;
  line-height: 1.125em;
  letter-spacing: 0.025em;
  text-align: center;
  text-transform: uppercase;
}
.cmp-teaser__title {
  margin-top: 0.5625rem;
  margin-bottom: 1rem;
  color: #ffffff;
  font-size: 2.25em;
  font-family: 'titleFont', 'titleFont Fallback';
  line-height: 3.0625rem;
  text-align: center;
  text-decoration: none;
}
.cmp-teaser__title-link {
  color: #ffffff;
  font-weight: 400;
  text-decoration: none;
}
.cmp-teaser__description {
  color: #ffffff;
  font-weight: 300;
  font-size: 1em;
  line-height: 1.75em;
  text-align: center;
}
.cmp-teaser__action-container {
  display: flex;
  justify-content: center;
  margin: 1rem 0;
}
.cmp-teaser__action-link {
  margin-right: 0.8em;
  padding: 0.5625em 1em 0.6875em 1em;
  color: $color-text-inverted;
  font-weight: 300;
  text-decoration: none;
  border-radius: 1.56em;
  white-space: nowrap;
}

.cmp-teaser__action-link:first-child {
  background-color: $color-accent;
  border: 0.125em solid $color-accent;

  &:hover {
    background-color: $color-accent-darker;
    border-color: $color-accent-darker;
  }
  &:focus {
    background-color: $color-accent-lighter;
    border-color: $color-background;
    outline: none;
    box-shadow: 0 0 0 0.125em $color-accent-lighter;
  }
  &:disabled {
    background-color: $color-shade-1;
    border-color: $color-shade-1;
  }
}

.cmp-teaser__action-link:not(:first-child) {
  color: $color-text-inverted;
  background-color: rgba(0, 0, 0, 0);
  border: 0.125em solid $color-background;

  &:hover {
    color: $color-text;
    background-color: $color-background;
  }
  &:focus {
    color: $color-text-inverted;
    background-color: rgba(0, 0, 0, 0);
    border: 0.125em solid $color-background;
    outline: none;
    box-shadow: 0 0 0 0.125em $color-accent-lighter;
  }
  &:disabled {
    color: $color-shade-1;
    border-color: $color-shade-1;
  }
}


//=================== Media queries ===================

@media (max-width: $breakpoint-mobile) {
  .cmp-teaser__action-link {
    height: 40px;
    width: 100px;
    font-size: smaller;
    padding: 0.1em;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
  }
}

