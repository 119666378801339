.container {
    &.container--justify-right {
        .cmp-container {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            text-align: right;
        }
        .cmp-button {
            margin-left: auto;
        }
    }
}