.container {
    &.container--justify-center {
        .cmp-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
        }
        .cmp-button {
            margin: auto;
        }

    }
}
