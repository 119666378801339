#main-footer {
	border: 0;
	margin: 0;
	padding: 5.0rem 7.5rem;
	@media (max-width: $breakpoint-large) {
		.cmp-container {
			display: block;
		}
		.aem-Grid.aem-Grid--3.aem-Grid--default--3, 
		.aem-Grid.aem-Grid--default--3 > .aem-GridColumn.aem-GridColumn--default--1, 
		.aem-Grid.aem-Grid--default--12 > .aem-GridColumn.aem-GridColumn--default--3,
		.aem-Grid.aem-Grid--default--12 > .aem-GridColumn.aem-GridColumn--default--6,
		.aem-Grid.aem-Grid--default--3 > .aem-GridColumn.aem-GridColumn--offset--default--0  {
			float: none;
			margin-bottom: 3.0rem;
			width: auto;
		}
	}
	@media screen and (max-width: 600px) {
		padding: 5.0rem 2.0rem;
	}
	a {
		color: $color-link !important;
		text-decoration: none;
		border-bottom: 2px solid $color-accent;
		margin: 0 2.0rem;
		transition: all .25s ease-in-out;
		white-space: nowrap;
		&:hover {
			border-color: $color-accent;
			color: $color-accent !important;
		}
	}
	.image.standard.aem-GridColumn--default--none.aem-GridColumn {
		margin-bottom: 1rem;
	}
	.cmp-image__image {
		display: block;
		margin: 0 auto;
		max-width: 25rem;
	}
	.footer-social {
		@media (max-width: $breakpoint-large) {
			.aem-Grid.aem-Grid--3.aem-Grid--default--3  {
				display: flex;
				justify-content: center;
				a {
					margin: 0 2.0rem;
				}
			}
		}
		float: right;
		width: 20.0rem;
		a {
			border: 0;
			display: inline;
			margin: 0 0 0 4.0rem;
		}
		img {
			display: inline;
			height: 4.0rem;
			width: 4.0rem;
		}
	}
}
.footer {
	overflow: hidden;
	ul {
		display: flex;
		justify-content: center;
		list-style: none;
		margin: 0 0 2.0rem;
		padding: 0;
		@media screen and (max-width: 600px) {
			display: block;
			margin: 0;
			li {
				margin: 1.0rem 0;
			}
		}
	}
	li {
		margin: 0 1.0rem;
		padding: 0;
	}
}
