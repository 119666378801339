#main-header {

	display: flex;
	box-sizing: border-box;
	height: 110px;
	border: none !important;
	background-color: black;
  
  
	& > .aem-Grid {
	  width: 100vw;
	  display: grid;
  
	  grid-template-areas: "nav logo";
	  grid-template-columns: 1fr 1fr;
	  grid-template-rows: 70px;
  
	  @media (min-width: 992px) {
		grid-template: unset;
		grid-template-areas: "logo nav";
		grid-template-columns: 1fr 50%;
		align-self: center;
	  }
	}
  
	.image {
	  grid-area: logo;
	  display: flex;
	  justify-self: flex-start;
	  margin: 0;
	  height: 110px;
	  left: 0;
	  position: absolute;
	  top: 0;
	  width: 100%;
  
	  @media (min-width: 992px) {
		left: unset;
	  }
  
	  .cmp-image {
		margin: auto;
	  }
  
	  .cmp-image__image {
		width: auto;
		height: 60px;
	  }
	}
  
	#header--mobile-logo {
	  display: flex !important;
	  visibility: visible;
  
	  @media (min-width: $screen-lg) {
		display: none !important;
		visibility: hidden;
	  }
	}
  
	#header--desktop-logo {
		display: flex;
		visibility: visible;  
	}
  
	.navigation {
	  display: none;
	  visibility: hidden;
  
	  @media (min-width: $screen-lg) {
		display: flex;
		visibility: visible;
	  }
  
	  grid-area: nav;
	  justify-content: flex-end;
	  width: 100%;
	  text-wrap: nowrap;
	}
  
	.cmp-navigation {
	  margin: 0 1em !important;
	}
  
	.cmp-navigation .cmp-navigation__item--level-0:nth-child(2):after {
	  height: 0;
	  width: 0;
	  margin: 0;
	  display: none;
	}
  
	.cmp-navigation__item {
	  text-transform: uppercase;
	  font-size: 18px;
	  letter-spacing: 1.35px;
	  list-style-type: none;
	  //font-family: topaz;
  
	  .cmp-navigation__item-link {
		color: $color-link;
		border-bottom: solid 2px $color-accent;
		padding: 0;
		margin: 0 15px;
	  }
	}

	.mobile-navigation {
  
	  display: flex;
	  visibility: visible;
	  grid-area: nav;
	  align-self: center;
	  z-index: 10;
  
	  @media (min-width: $screen-lg) {
		display: none;
		visibility: hidden;
	  }
  
	  .cmp-navigation__item {
		font-size: 20px;
		padding: 15px;
		
		a {
		  text-decoration: none;
		  color: $color-link;
		}
	  }
  
	  button {
		cursor: pointer;
	  }
  
	  .modal {
		position: fixed;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		max-width: 360px;
		visibility: hidden;
		background-color: $color-background;
		z-index: 20;
	  }
  
	  .modal-content {
		padding: 15px;
	  }
  
	  .modal-header {
		padding-bottom: 15px;
		display: flex;
	  }
  
	  .modal-title {
		margin: 2px 0 0 10px;
	  }
  
	  .modal-close-btn {
		border: none;
		background-color: transparent;
	  }
  
	  .modal-body {
		font-size: 20px;
		line-height: 32px;
		letter-spacing: 0.35px;


		.cmp-navigation__item {
			text-decoration: underline $color-accent;
			a:hover, a:focus {
				color: $color-accent !important;
			}
			
		}
  
	  }
  
	  .mobile-nav-trigger {
		border: none;
		background-color: transparent;
	  }
  
	  .mobile-nav-icon {
		margin-left: 30px;
	  }
	}
  }
  
  // sticky header
  .cmp-experiencefragment--header {
	top: 0;
	z-index: 100;
	background-color: transparent;
  }
  